
import dialogShell from '@/components/common/dialog-shell/index.vue';
import HttpRequest from '@/util/axios.config';
import {
    computed,
    defineComponent, ref, Ref, watch, PropType
} from 'vue';
import { access } from '@/data';
import { format } from '@/methods/date';
import Notice from '@/util/notice.base';
import { ElLoading } from 'element-plus';
import { ExportLogData, PostExportPMLogData, ExportTimeData } from './all.type';

export default defineComponent({
    components: {
        dialogShell
    },
    props: {
        title: {
            type: String,
            required: true
        },
        type: {
            type: Number,
            required: true
        },
        exportTime: {
            type: Object as PropType<ExportTimeData>,
            required: true
        }
    },
    setup(props, { emit }) {
        const formRef: Ref<any> = ref({});
        const formData: Ref<ExportLogData> = ref({
            Type: props.type.toString(),
            ExportType: access.exportExcel,
            BeginTime: '',
            EndTime: ''
        });

        const rules: Ref<{ [key: string]: Array<object> }> = ref({
            ExportType: [
                { required: true, message: WordList.ProperAllTextLogsAndCapturePrompt, trigger: 'blur' }
            ]
        });

        const beginOptions = (time: Date) => {
            if (formData.value.ExportType === access.exportExcel) {
                return (time.getTime()
                        < new Date(new Date(props.exportTime.ExportOnlyBeginTime).getTime() - 3600 * 1000 * 24).getTime()
                        || time.getTime() > new Date(formData.value.EndTime).getTime());
            }
            return true;
        };

        const endOptions = (time: Date) => {
            if (formData.value.ExportType === access.exportExcel) {
                return (
                    time.getTime() < new Date(formData.value.BeginTime).getTime()
                        || time.getTime() > new Date(props.exportTime.ExportOnlyEndTime).getTime()
                );
            }
            return (time.getTime() > Date.now()
                    || time.getTime() < new Date(props.exportTime.ExportBeginTime).getTime() - 3600 * 1000 * 24);
        };

        // 时间控件需要
        const isEndDisable = ref(false);
        const flagTime = ref('');
        watch(computed(() => formData.value.ExportType), (val: string) => {
            formData.value.BeginTime = '';
            formData.value.EndTime = '';
            isEndDisable.value = false;
            if (val === access.exportExcel) {
                // 加个正则为了解决new Date 8小时时差问题
                formData.value.BeginTime = props.exportTime.ExportOnlyBeginTime.replace(/-/g, '-');
                formData.value.EndTime = props.exportTime.ExportOnlyEndTime;
            } else if (val === access.exportAll) {
                isEndDisable.value = true;
                if (props.exportTime.ExportEndTime === false) {
                    formData.value.BeginTime = props.exportTime.ExportBeginTime;
                    return;
                }
                if (
                    new Date(props.exportTime.ExportBeginTime).getTime() > new Date(props.exportTime.ExportEndTime as string).getTime()
                ) {
                    formData.value.BeginTime = props.exportTime.ExportBeginTime;
                    formData.value.EndTime = props.exportTime.ExportBeginTime;
                    flagTime.value = props.exportTime.ExportBeginTime;
                } else {
                    formData.value.BeginTime = props.exportTime.ExportBeginTime;
                    formData.value.EndTime = props.exportTime.ExportEndTime as string;
                    flagTime.value = props.exportTime.ExportEndTime as string;
                }
            } else {
                isEndDisable.value = true;
            }
        }, { immediate: true });

        /**
             countFlag：
             0：所选时间段条数正常（小于19999条）
             1：所选时间段条数不正常（大于19999条）
             2：所选时间段条数为0条
             timeFlag：
             0：所选时间段条数正常 或者开始时间小于等于结束时间
             1：今天 > 开始时间 > 结束时间
             2：今天 = 开始时间 > 结束时间
             */
        const downloadRef: Ref<any> = ref({});
        const downloadUrl = ref('');
        const submit = () => {
            formRef.value.validate((valid: boolean) => {
                if (!valid) return false;

                const params = { ...formData.value };
                params.BeginTime = format(params.BeginTime, 'YYYY-MM-DD');
                params.EndTime = format(params.EndTime, 'YYYY-MM-DD');

                if (params.ExportType === access.exportAll) {
                    if (props.exportTime.CountFlag === 2) {
                        Notice.messageBox('alert', WordList.ProperAllTextExportLogCountNull,
                            WordList.ProperAllTextExportLogPrompt, undefined, WordList.TabelFootSure)(() => false);
                        return true;
                    }
                    if (props.exportTime.TimeFlag === 2) {
                        Notice.messageBox('alert', WordList.ProperAllTextExportLogNow,
                            WordList.ProperAllTextExportLogPrompt, undefined, WordList.TabelFootSure)(() => false);
                        return true;
                    }

                    if (params.EndTime > format(flagTime.value)) {
                        Notice.messageBox('alert', WordList.ProperAllTextExportLogDate.format(flagTime.value),
                            WordList.ProperAllTextExportLogPrompt, undefined, WordList.TabelFootSure)(() => false);
                        return true;
                    }
                }
                const typeMap = [];
                typeMap[1] = 'doorLog';
                typeMap[4] = 'callHistory';
                typeMap[3] = 'temperatureCapture';
                typeMap[2] = 'capture';
                const mapParams = {
                    exportLogType: typeMap[Number(params.Type)],
                    isOnlyRecord: params.ExportType === '1',
                    beginTime: params.BeginTime,
                    endTime: params.EndTime
                };
                HttpRequest.post('v4/web/office/log/pm/createExportLogTask', mapParams,
                    [((res: { code: number; data: {
                        exportLogType: string;
                        traceId: string;
                        isShowMorePrompt: boolean;
                        timeBefore: string;
                    }; msg: string; }) => {
                        const data = { ...res.data };
                        if (params.ExportType === access.exportAll) {
                            if (props.exportTime.TimeFlag === 1 && props.exportTime.CountFlag === 1) {
                                Notice.messageBox('alert', WordList.ProperAllTextExportLogCountExceed,
                                    WordList.ProperAllTextExportLogPrompt, undefined, WordList.TabelFootSure)(() => emit('close'));
                            } else {
                                Notice.messageBox('alert', WordList.ProperAllTextExportLogOK,
                                    WordList.ProperAllTextExportLogPrompt, undefined, WordList.TabelFootSure)(() => emit('close'));
                            }
                        } else if (data.traceId) {
                            if (data.isShowMorePrompt) {
                                Notice.messageBox('alert', WordList.ProperAllTextExportLogCountExceedExcel,
                                    WordList.ProperAllTextExportLogPrompt, undefined, WordList.TabelFootSure);
                            }

                            const loadingInstance = ElLoading.service();
                            const downloadElement = downloadRef.value;
                            const times = setInterval(() => {
                                HttpRequest.get('v4/web/office/log/pm/getExportLogUrl', [{ traceId: data.traceId }, false],
                                    ((urlRes: { data: { downloadUrl: string } }) => {
                                        if (urlRes.data.downloadUrl) {
                                            downloadElement.href = urlRes.data.downloadUrl;
                                            downloadElement.click();
                                            clearInterval(times);
                                            loadingInstance.close();
                                        }
                                    }));
                            }, 2000);
                        } else {
                            Notice.messageBox('alert', WordList.ProperAllTextExportLogDate.format(data.timeBefore),
                                WordList.ProperAllTextExportLogPrompt, undefined, WordList.TabelFootSure)(() => emit('close'));
                        }
                    }), true]);

                return true;
            });
        };
        return {
            formRef,
            submit,
            formData,
            emit,
            rules,
            beginOptions,
            endOptions,
            isEndDisable,
            downloadUrl,
            downloadRef
        };
    }
});

