
import {
    defineComponent, Prop, PropType, reactive
} from 'vue';
import { RecordType } from '@/views/pm/home/all.type';
import { dayjsFormat } from '@/methods/dayjs';
import { community } from '@/data';

export default defineComponent({
    emits: ['click'],
    props: {
        recordData: {
            type: Object as PropType<RecordType>,
            required: true
        }
    },

    setup() {
        const timeFormatMap = {
            2: 'YYYY-MM-DD hh:mm:ss A',
            3: 'YYYY-MM-DD HH:mm:ss',
            4: 'MM-DD-YYYY hh:mm:ss A',
            5: 'MM-DD-YYYY HH:mm:ss',
            6: 'DD-MM-YYYY hh:mm:ss A',
            7: 'DD-MM-YYYY HH:mm:ss'
        };
        return {
            timeFormatMap,
            dayjsFormat,
            community
        };
    }
});
