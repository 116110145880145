
import {
    computed, defineComponent, PropType, ref, Ref, watch
} from 'vue';
import { propertyBaseRouter } from '@/router/data';
import remove from '@/methods/remove-func';
import HttpRequest from '@/util/axios.config';
import router from '@/router/index';
import secondaryNav from '@/components/common/secondary-nav/index.vue';
import { dayjsFormat } from '@/methods/dayjs';
import { limitErrorImg } from '@/methods/basicFun';
import {
    ListActionItem,
    ListHeaderItem,
    listPagination, RequestData
} from '@/components/common/list';
import {
    getMenus, getLogPageData, LogTypeEnum
} from '@/views/pm/log/util';
import { DoorLogSearchData } from '@/views/pm/log/search/all.type';
import EnlargeImage from '@/components/common/enlarge-image/index.vue';
import { pmLog, community, communityType } from '@/data';
import ExportLogDialog from '@/components/view/pm/export-log-dialog';
import propertyBreadCrumb from '@/components/view/pm/bread-crumb';
import { communityForPmApi } from '@/api';
import { logType, ExportTimeData } from './all.type';
import SearchModel from './search/index.vue';
import LogLocation from './location/log-location.vue';

const breadCrumb = [{ label: WordList.ProperAllTextLogs }];
export default defineComponent({
    components: {
        listPagination,
        secondaryNav,
        SearchModel,
        EnlargeImage,
        ExportLogDialog,
        LogLocation,
        propertyBreadCrumb
    },
    props: {
        type: {
            type: String as PropType<logType>,
            default: 'doorLogs'
        }
    },
    setup(props) {
        const imgItem = ref<Array<any>>([]);

        const enableRequest = ref(false);
        const communityInfoRequested = ref(false);

        const logTypes = ['doorLogs', 'callHistory', 'capture'];

        const setAllImg = (el: any) => {
            if (el) {
                limitErrorImg(el);
                imgItem.value.push(el);
            }
        };
        const secondMenus = ref(getMenus());
        // list 所需数据
        const headers: Ref<Array<ListHeaderItem>> = ref([]);
        const listRequestData: Ref<RequestData> = ref({
            url: '',
            param: {}
        });
        const action: Ref<Array<ListActionItem> | false> = ref(false);
        const showCheck = ref(true);
        const defaultAction: Ref<Array<string>> = ref([]);
        const deleteUrl = ref('');

        const updateToList = ref(0);
        const saveParamInPath = ref(false);
        const activeNavItem = ref(props.type);

        // 获取结束时间
        const isShowExportLog = ref(false);
        const exportTime = ref({});
        const exportType = ref(1);
        const exportTitle = {
            doorLogs: WordList.ProperAllTextDoorLogs,
            callHistory: WordList.NavInHtmlMenuCall,
            temperatureLogs: WordList.ProperAllTextTemperatureLogs,
            capture: WordList.capture
        };
        const getExportTime = () => {
            if (props.type !== 'auditLogs' && props.type !== 'motionAlerts') {
                exportType.value = LogTypeEnum[props.type];
                const typeMap = [];
                typeMap[1] = 'doorLog';
                typeMap[4] = 'callHistory';
                typeMap[3] = 'temperatureCapture';
                typeMap[2] = 'capture';
                pmLog.getExportTime({ exportLogType: typeMap[LogTypeEnum[props.type]] }, (res: {
                    beginTime: string;
                    endTime: string;
                    onlyExcelBeginTime: string;
                    onlyExcelEndTime: string;
                    isExceedMaxNum: boolean;
                    isEmpty: boolean;}) => {
                    const mapData = {
                        ExportBeginTime: res.beginTime,
                        ExportEndTime: res.endTime,
                        ExportOnlyBeginTime: res.onlyExcelBeginTime,
                        ExportOnlyEndTime: res.onlyExcelEndTime,
                        CountFlag: 0,
                        TimeFlag: 0
                    };
                    if (res.isEmpty) {
                        mapData.CountFlag = 2;
                    } else if (res.isExceedMaxNum) {
                        mapData.CountFlag = 1;
                    }
                    const currentDate = new Date();
                    const beginTime = new Date(res.beginTime);
                    const endTime = new Date(res.endTime);

                    // 只比较年月日
                    const currentDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
                    const beginDay = new Date(beginTime.getFullYear(), beginTime.getMonth(), beginTime.getDate());
                    const endDay = new Date(endTime.getFullYear(), endTime.getMonth(), endTime.getDate());

                    if (beginDay > endDay) {
                        if (currentDay > beginDay) {
                            mapData.TimeFlag = 1;
                        } if (currentDay.getTime() === beginDay.getTime()) {
                            mapData.TimeFlag = 2;
                        }
                    }
                    exportTime.value = mapData;
                });
            }
        };
        const closeExportLogDialog = () => {
            getExportTime();
            isShowExportLog.value = false;
        };

        const logSaveDaysTips = computed(() => {
            let logTip = WordList.ProperAllTextLogSaveDays;

            switch (props.type) {
                case 'doorLogs':
                    logTip = WordList.DoorLogSaveDays;
                    break;
                case 'callHistory':
                    logTip = WordList.CallHistorySaveDays;
                    break;
                case 'capture':
                    logTip = WordList.CaptureLogSaveDays;
                    break;
                case 'temperatureLogs':
                    logTip = WordList.TemperatureLogSaveDays;
                    break;
                case 'motionAlerts':
                    logTip = WordList.MotionLogSaveDays;
                    break;
                default:
                    break;
            }

            return logTip;
        });

        watch([activeNavItem, community.ProjectType, community.enableShowLog, community.communityInfoRequested], () => {
            saveParamInPath.value = false;
            const pageData = getLogPageData(community.ProjectType.value)[activeNavItem.value];
            headers.value = pageData.header;
            listRequestData.value = pageData.requestData;
            showCheck.value = pageData.showCheck ? pageData.showCheck : false;
            defaultAction.value = pageData.defaultAction ? pageData.defaultAction : [];
            deleteUrl.value = pageData.deleteUrl ? pageData.deleteUrl : '';
            action.value = pageData.action;
            if (activeNavItem.value === 'motionAlerts') {
                action.value = [{
                    type: 'delete2',
                    event(data: { id: string }) {
                        remove(WordList.TabelDeleteText, () => {
                            HttpRequest.post('v4/web/office/log/pm/deleteMotionLog', {
                                id: [data.id]
                            }, () => {
                                updateToList.value += 1;
                            });
                        });
                    },
                    class: 'el-icon-delete delete-icon',
                    title: WordList.TabelConfigInHtmlDelete
                }];
            }
            getExportTime();

            // 拥有log访问权限或者访问的不是log，才发送请求
            if (community.communityInfoRequested.value && (community.enableShowLog.value || !logTypes.includes(activeNavItem.value))) {
                enableRequest.value = true;
            }
        }, {
            immediate: true
        });

        watch([community.isNew, communityInfoRequested], () => {
            if (community.ProjectType.value === 'community' && community.isNew.value) {
                secondMenus.value.push({
                    label: WordList.ProperAllTextOperationLogs,
                    key: 'auditLogs',
                    path: 'auditLogs'
                });
            }
        }, {
            immediate: true
        });

        communityForPmApi.getPMCommunityDetail((res: communityType.CommunityDetail) => {
            communityInfoRequested.value = true;
            community.communityInfoRequested.value = true;
            community.enableShowLog.value = res.EnableShowLog === 1;
            if (!community.enableShowLog.value && logTypes.includes(props.type)) {
                activeNavItem.value = 'temperatureLogs';
                router.push({
                    query: {
                        type: activeNavItem.value
                    }
                });
            } else {
                activeNavItem.value = props.type;
            }
            secondMenus.value = getMenus();
        });

        const changePath = (key: string, type: string) => {
            router.push({
                query: {
                    type
                }
            });
        };

        // 搜索按钮事件
        const search = (searchData: DoorLogSearchData) => {
            listRequestData.value.param = { ...searchData };
            updateToList.value += 1;
            saveParamInPath.value = true;
        };

        // 图片放大的组件
        const isShowEnlargeImage = ref(false);
        const picAddress = ref('');
        const showEnlargeImage = (address: string) => {
            picAddress.value = address;
            isShowEnlargeImage.value = true;
        };

        // 控制温度显示类型
        const unit = ref('fahrenheit');
        const unitChange = (unitReturn: string) => {
            unit.value = unitReturn;
        };
        const handleImageError = (event: ErrorEvent) => {
            console.log('event', event);
            // const target = event.target;
            // if (target) {
            //     // 隐藏加载失败的图片
            //     target.style.display = 'none';
            // }
            // // 显示错误图标
            // this.isImageError = true;
        };

        function formatApt(item: any) {
            if (item.roomNum !== '--') {
                if (item.floor) {
                    return `${item.roomNum} (${WordList.Floor} ${item.floor})`;
                }
                return `${item.roomNum}`;
            }
            return '--';
        }

        const mapWordOfAction = {
            call: WordList.ProperAllTextCall,
            tempKeyUnlock: WordList.ActionUnlock.format(WordList.ProperAllTextTempKey),
            localKeyUnlock: WordList.ActionUnlock.format(WordList.ProperAllTextPIN),
            rfCardUnlock: WordList.ActionUnlock.format(WordList.DeliveryRfCard),
            faceUnlock: WordList.ActionUnlock.format(WordList.ProperAllTextPhotos),
            remoteOpenDoor: WordList.ActionUnlock.format(WordList.ProperAllTextSmartplus),
            callUnlockApp: WordList.ActionUnlock.format(WordList.ProperAllTextSmartplus),
            callUnlockIndoor: WordList.ActionUnlock.format(WordList.TabelPersonDeviceInHtmlDeviceTypeIndoor),
            callUnlockGuardPhone: WordList.ActionUnlock.format(WordList.SelectManagementPhone),
            pmUnlock: WordList.ActionUnlock.format(WordList.Manually),
            autoUnlock: WordList.ActionUnlock.format(WordList.Automatically),
            before: '--',
            nfcUnlock: WordList.ActionUnlock.format(WordList.NFC),
            bleUnlock: WordList.ActionUnlock.format(WordList.Bluetooth),
            handScreen: WordList.CaptureOnSmartPlus,
            callCapture: WordList.ProperAllTextCall,
            pmLock: WordList.ActionLock.format(WordList.Manually),
            autoLock: WordList.ActionLock.format(WordList.Auto),
            lockApp: WordList.ActionLock.format(WordList.ProperAllTextSmartplus),
            open: WordList.Open,
            close: WordList.TabelFootClose,
            deliveryUnlock: WordList.DeliverySystemVerification,
            booking: WordList.AmenityReservation,
            idAccess: WordList.IDAccess,
            handset: WordList.Handset
        };
        const mapWordOfDoorLogType = {
            call: WordList.ProperAllTextCall,
            doorRelease: WordList.ProperAllTextDoorRelease,
            doorClose: WordList.DoorClose,
            entry: WordList.Entry,
            exit: WordList.Exit,
            entryViolation: WordList.EntryViolation,
            exitViolation: WordList.ExitViolation
        };
        const mapWordOfTemperatureStatus = {
            abnormal: WordList.ProperAllTextAbnormal,
            normal: WordList.ProperAllTextNormal,
            low: WordList.ProperAllTextLow
        };
        const mapWordOfResponse = {
            success: WordList.TabelChangePwStepSuccess,
            failed: WordList.PropertyAllTextFailed,
            offline: WordList.SelectOffline
        };

        const timeFormatMap = {
            2: 'YYYY-MM-DD hh:mm:ss A',
            3: 'YYYY-MM-DD HH:mm:ss',
            4: 'MM-DD-YYYY hh:mm:ss A',
            5: 'MM-DD-YYYY HH:mm:ss',
            6: 'DD-MM-YYYY hh:mm:ss A',
            7: 'DD-MM-YYYY HH:mm:ss'
        };

        return {
            headers,
            listRequestData,
            updateToList,
            changePath,
            secondMenus,
            activeNavItem,
            action,
            search,
            isShowEnlargeImage,
            picAddress,
            showEnlargeImage,
            getExportTime,
            showCheck,
            exportTime,
            exportType,
            exportTitle,
            isShowExportLog,
            closeExportLogDialog,
            unitChange,
            unit,
            defaultAction,
            deleteUrl,
            breadCrumb,
            saveParamInPath,
            setAllImg,
            community,
            logSaveDaysTips,
            enableRequest,
            mapWordOfAction,
            mapWordOfDoorLogType,
            mapWordOfTemperatureStatus,
            mapWordOfResponse,
            formatApt,
            timeFormatMap,
            dayjsFormat
        };
    }
});
