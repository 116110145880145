<template>
    <div class="policy">
        <h2><strong>プライバシーポリシー</strong></h2>
        <h3>最終更新日: 2025 年 1 月 6 日</h3>
        <h3><strong>プライバシーポリシーの概要</strong></h3>
        <p>当社のサービスへようこそ。このサービスは、SMART-PLUS PTE.
            LTD.（以下、「SMART-PLUS」、「当社」または「弊社」といいます）によって提供および管理されています。このサービスを利用することにより、お客様はこのプライバシーポリシーに同意したことになります。</p>
        <p>サービスには以下が含まれます:</p>
        <ul class="policy-ul">
            <li>第三者の
                サイト（以下「ポータル」）を除く、*cloud.akuvox.com、*cloud.akubela.com（*cloudはecloud、ucloud、jcloud、scloudなどを含む）、およびそれに関連するすべてのサイトからアクセスできる当社のウェブサイトとユーザーアカウント
            </li>
            <li>ウェブサイトを通じてアクセス可能なオンライン サービス (「Web サービス」)。</li>
            <li>Akuvox SmartPlus、BelaHome など、モバイル デバイスにダウンロードできるソフトウェア アプリケーション (「モバイル アプリ」)。</li>
            <li>ドアホン、屋内モニター、Android ビデオ電話、アクセス制御端末、ホームコントロールパネルなどを含むがこれらに限定されない、サービスの関連製品 (「デバイス」)。</li>
            <li>ウェブサイトおよびモバイル アプリで利用可能なすべてのサービス (「利用可能なサービス」)。</li>
            <li>「サービス」という用語は、ウェブサイト、ウェブ サービス、モバイル アプリ、デバイス、および利用可能なサービスを意味します。</li>
        </ul>
        <p>プライバシーポリシーでは、当社について、当社がお客様について収集する情報、お客様の個人情報の使用方法と開示方法、お客様の情報を保護する方法の詳細、およびお客様のデータプライバシー権について説明します。当社のサービスはお客様の個人情報を非常に真剣に受け止めており、お客様が個人情報を管理できるようにしています。当社は、お客様の個人データとプライバシーを保護するために強力な暗号化技術を使用し、すべてのデータを管理するための厳格なポリシーを採用しています。当社は、いわゆる一般データ保護規則（「GDPR」）およびカリフォルニア州消費者プライバシー法（「CCPA」）に従って、お客様に関する個人データを常に保護します。
        </p>
        <h3><strong>A. 当社について</strong></h3>
        <p>本サービスは、納税者番号202116626G、登録事務所が6 RAFFLES QUAY #14-06, Singapore (048580)であるSMART-PLUS PTE.
            LTD.によって提供および管理されています。</p>
        <h3><strong>B. 当社が収集する個人情報</strong></h3>
        <p>個人情報は、適用法に基づいてサービスを提供する目的で当社のサービスによって収集されます。</p>
        <p>「個人情報」は「個人データ」と同じ意味を持ち、特定された、または特定可能な自然人（「データ主体」）に関連するあらゆる情報を含みます。特定可能な個人とは、特に識別番号またはその人の身体的、生理的、精神的、経済的、文化的、または社会的アイデンティティに固有の
            １つ以上の要素を参照することにより、直接的または間接的に特定できる個人です。</p>
        <p>当社は、お客様にサービスを提供するために必要な個人情報の提供をお願いしております。
            これらの情報の一部または全部をご提供いただけない場合、当社はそれらの情報を処理しませんが、サービスまたはその機能の一部が制限される可能性があります。</p>
        <p>次の部分では、当社のサービスをご利用になる際に当社が収集する情報の種類についてご理解いただきたいと思います。</p>
        <p><strong>1. ユーザーアカウント登録</strong></p>
        <p>サービス アカウントを作成するには、登録プロセス中に次の個人情報を提供する必要があります。</p>
        <p>プロパティマネージャーアカウント：</p>
        <p>名前</p>
        <p>姓</p>
        <p>メールアドレス</p>
        <p> </p>
        <p>エンドユーザーアカウント：</p>
        <p>名前</p>
        <p>姓</p>
        <p>メールアドレス</p>
        <p>携帯電話番号</p>
        <p>住所 （個人ユーザーの場合は、居住地住所を収集します。ビジネスユーザーの場合は、ビジネス住所を収集します。）</p>
        <p><strong>2. サービスの利用</strong></p>
        <p>お客様が当社のサービスをご利用になる際、当社は以下の個人情報（またはその一部）を収集します。</p>
        <ul class="policy-ul">
            <li>携帯電話のIPアドレス</li>
            <li>携帯電話のBluetooth情報</li>
            <li>デバイス名</li>
            <li>デバイスのIPアドレスとMacアドレス</li>
            <li>デバイスと携帯電話の位置情報</li>
            <li>認証情報</li>
            <li>モバイルアプリで処理され、クラウドに保存される画像、動画、音声</li>
            <li>デバイスとシステムの構成変更</li>
            <li>ログ情報、特に通話ログ、ドア開閉のスナップショット、ドア開閉履歴、自動化履歴</li>
            <li>支払い状況</li>
        </ul>
        <p><strong>3. トラブルシューティングと改善のサービス</strong></p>
        <p>当社は、サービス使用中に発生する問題を解決し、サービスを最適化するために、モバイル アプリの使用中に以下の情報を収集します。</p>
        <ul class="policy-ul">
            <li>最終ログイン</li>
            <li>使用期間</li>
            <li>音声通話とビデオ通話</li>
            <li>システムログ</li>
            <li>インストールされたアプリケーションデータ</li>
            <li>連絡先名などの個人情報が含まれている場合の設定のダウンロード</li>
        </ul>
        <p><strong>4. サービスの加入</strong></p>
        <p>サービス料金の支払いを選択した場合、以下の個人情報が当社に提供されます。</p>
        <ul class="policy-ul">
            <li>名前</li>
            <li>姓</li>
            <li>電子メールアドレス</li>
            <li>居住国</li>
            <li>お支払いの詳細</li>
        </ul>
        <p>これらの個人データの処理は、お客様にサービスを提供するために必要であり、お客様との契約の履行に必要です。</p>
        <p> </p>
        <p><strong>5.サードパーティのサービス</strong></p>
        <p>お客様が当社のサービスを介して第三者のサービスをご利用になる場合、当社はお客様のリクエストに応え、お客様にリクエストされたサービスを提供するため、および当社のサービスを改善するために個人情報を処理します。当社は、サービス提供の一環として、またお客様に合わせたコンテンツを提供するために、これらの第三者サービスとの契約を通じて、ユーザーを識別および管理するための個人情報を受け取ります。
        </p>
        <p>ソーシャルシェア機能を提供するために、当社はMobTech
            ShareSDKと統合しました。これにより、システム情報、ネットワークステータス、IMEI、OAID、IMSI、アプリリスト、携帯電話基地局、ソーシャルプラットフォームOpenID、携帯電話の地理的位置などの個人情報を収集します。詳細については、www.mob.com/about/policyにアクセスして、関連するプライバシーポリシーと条件をご覧ください。
        </p>
        <p>サードパーティのサービスを使用する場合は、サードパーティの利用規約またはサービス規約、およびそれぞれのプライバシー通知が適用されることにご注意ください。デバイスでサードパーティの利用規約を有効にする前に、これらの利用規約を注意深くお読みになることをお勧めします。当社がお客様の個人情報を処理することは、お客様との契約を履行するため、および当社の正当な利益にかなうために必要です。
        </p>
        <p>また、当社は第三者のサービスからお客様に関する情報を収集する場合もあります。これらの個人データの処理は、お客様にサービスを提供するために必要であり、したがってお客様との契約の履行に必要です。</p>
        <p>重要: このデータ収集にはいつでも異議を申し立てることができます (理由や都合によります)。その場合、当社はこの目的での個人データの収集を直ちに停止します。</p>
        <h3><strong>C. Cookieおよび類似の技術</strong></h3>
        <p>Cookieや類似の技術は、インターネットで一般的に使用されている技術です。Cookieは、Web サイトにアクセスしたときにコンピューターに送信される文字列を含む小さなファイルです。Web
            サイトに再度アクセスすると、Cookieによって Web
            サイトがブラウザーを認識できるようになります。また、Cookieにはユーザーの設定やその他の情報も保存されます。ブラウザーをリセットしてすべてのCookieを拒否したり、Web
            サイトがコンピューターにCookieを送信しているときに警告を表示したりできます。ただし、Cookieがないと、一部の Web サイトの機能やサービスが正しく動作しない場合があります。</p>
        <p>お客様がウェブサイトおよびモバイル アプリにアクセスして使用する場合、当社はこれらの技術を使用して、お客様のデバイスに Cookie
            または匿名識別子を送信し、情報を収集して保存することがあります。お客様の地域の法律でインターネット プロトコル (IP) アドレスまたは類似の識別マークが個人情報として扱われている場合を除き、当社は Cookie
            やその他の技術を通じて収集された情報を非個人情報として扱います。当社は、以下のサービスに Cookie および類似の技術を使用します。</p>
        <p><strong>1. 当社のサービスの安全かつ効率的な運営を確保するため</strong></p>
        <p>当社は、お客様がサービスに安全にログインしていることを確認したり、詐欺などの違法行為に遭遇する可能性があるかどうかを評価したりするために、認証またはセキュリティ Cookie
            や匿名識別子を設定することがあります。これらのテクノロジーは、サービスの効率性やシステムのログインおよび応答速度の向上にも役立ちます。</p>
        <p><strong>2. より簡単なアクセス体験を実現するため</strong></p>
        <p>このようなテクノロジーを使用すると、ワンクリック ログインや検索履歴などの方法を使用して、個人情報を繰り返し入力したり、検索コンテンツを入力したりする手順やプロセスを回避できます。</p>
        <p><strong>3. ログファイルの場合</strong></p>
        <p>ほとんどのウェブサイトと同様に、当社は特定の情報を収集し、それをログ ファイルに保存します。このような情報には、インターネット プロトコル (IP) アドレス、ブラウザーの種類、インターネット サービス プロバイダー
            (ISP)、参照元/終了ページ、オペレーティング システム、日付/タイム スタンプ、および/またはクリックストリーム データが含まれる場合があります。当社は、自動的に収集されたログ
            データを、当社が収集するその他の情報と関連付けることはありません。</p>
        <p><strong>4. モバイル分析の場合</strong></p>
        <p>ウェブサイトおよびモバイル アプリでは、お客様の携帯電話でのモバイル
            ソフトウェアの機能をより深く理解するために、モバイル分析ソフトウェアを使用しています。このソフトウェアは、アプリケーションの使用頻度、アプリケーション内で発生したイベント、累積使用状況、パフォーマンス
            データ、アプリケーションのクラッシュが発生した場所のデータなどの情報を記録する場合があります。当社は、分析ソフトウェアに保存された情報を、お客様がウェブサイトおよびモバイル
            アプリに送信する個人情報と関連付けることはありません。</p>
        <h3><strong>D. 個人情報の利用目的</strong></h3>
        <p>当社は収集した個人情報を以下の目的で使用することがあります。</p>
        <p>1. お客様にサービスを提供するため。当社は、訪問者がインターホンを通じてお客様に電話をかけることなど、お客様がサービスを利用できるようにするためにお客様の個人情報を使用します。</p>
        <p>2. トラブルシューティングとメンテナンスを行うため。ユーザーがインストールしたアプリケーションのデータを https://maintenance.akuvox.com
            にアップロードし、アプリケーションの問題を分析および診断して、サービス エクスペリエンスを向上させます。</p>
        <p>3. 当社は、サービス調査の実施、ご質問への回答、新サービスの宣伝など、マーケティング目的でお客様と連絡を取るためにお客様の個人情報を使用する場合があります。</p>
        <p>4. サービス向上のためのウェブサイトおよびモバイルアプリの使用に関する統計情報のデータ分析、調査、開発などの社内使用のため。</p>
        <p>5.
            当社のサービスの共有機能を強化するために、お客様のデバイスにインストールされているアプリケーションに関する情報を収集する場合があります。この情報は、これらのアプリケーションを通じてコンテンツを共有できるようにし、より便利な共有オプションを提供するためにのみ使用されます。
        </p>
        <p>6. ソフトウェアの検証とサービスのアップグレードを実行します。</p>
        <p>7.
            法律を遵守し、権利を行使します。当社は、適用法の評価と遵守の確保、資産の保護、違法行為や不正行為の申し立ての調査や防御（法的助言の取得、法的権利の確立、行使、防御を含む）に合理的に必要な範囲でお客様の個人情報を使用する場合があります。また、裁判所命令、司法機関またはその他の政府機関の召喚状や捜索令状に応じる場合もあります。
        </p>
        <h3><strong>E. 個人情報の開示方法</strong></h3>
        <p>当社は、いかなる目的であっても個人情報を第三者に販売することはありません。当社は、以下のカテゴリーの受信者にお客様の個人情報を開示する場合があります。</p>
        <p><strong>1. サービスプロバイダー</strong></p>
        <p>当社は、お客様の地域市場における当社の事業運営をサポートするサービスを提供する第三者サービスプロバイダーにお客様の情報を開示する場合があります。これには、家主、不動産管理会社、および当社の地域ビジネスパートナーが含まれます。
        </p>
        <p><strong>2. 法執行</strong></p>
        <p>法律で義務付けられている場合、または法的義務、手続き、要請に従うために合理的に必要な場合、お客様の情報を法執行機関、公的機関、またはその他の組織と共有します。データ送信プロセス中、当社はお客様の情報のセキュリティを確保するためにデータを暗号化します。
        </p>
        <h3><strong>F. 個人データの保存と個人データの転送</strong></h3>
        <p>当社のサービスのインフラストラクチャーは、北カリフォルニア、米国、ドイツのフランクフルト、シンガポールなど、いくつかの場所にある Web
            サービスで実行されており、すべての個人データは対応するサーバーに保存されます。個人データは、サーバーから Amazon Cloud または Alibaba Cloud
            にバックアップされます。データ転送プロセス中、当社はデータのセキュリティを確保するためにデータを暗号化して保護します。</p>
        <p>このプライバシー ポリシーに記載されている目的でその情報を処理する際に、当社は、お客様に要求されたサービスを提供するために、お客様の情報の特定の部分にアクセスすることがあります。当社の企業コンテンツ
            データ管理規則により、すべてのデータ転送が適用される現地の法律および規制に準拠していることが保証されます。</p>
        <h3><strong>G. お客様の権利と選択肢</strong></h3>
        <p>当社のサービスをご利用いただく際、お客様は個人データに対して以下の権利を有します。</p>
        <p><strong>アクセス権</strong></p>
        <p>お客様には、お客様の個人情報が処理されているかどうかの確認を得る権利があります。また、当社がお客様の個人情報を処理する場合、お客様には個人情報および以下の情報にアクセスする権利があります。</p>
        <p>a) 処理の目的</p>
        <p>b) 関係する個人データのカテゴリー</p>
        <p>c) 個人データが開示された、または開示される予定の受信者、特に第三国または国際機関の受信者。</p>
        <p>d) 可能であれば、個人データが保存される予定の期間、またはそれが不可能な場合は、その期間を決定するために使用される基準。</p>
        <p>e) 管理者に対して、個人データの訂正または消去、もしくはお客様に関する個人データの処理の制限を要求する権利、またはそのような処理に異議を申し立てる権利の存在。</p>
        <p>f) 監督当局に苦情を申し立てる権利</p>
        <p>g) 個人データがお客様から収集されていない場合、その情報源に関する入手可能な情報。</p>
        <p>h)
            GDPR第22条(1)および(4)に規定されるプロファイリングを含む自動化された意思決定の存在、および少なくともその場合には、関連するロジックに関する意味のある情報、ならびにそのような処理がお客様にとってどのような意義と予想される結果をもたらすか
        </p>
        <p>個人データが第三国または国際機関に転送される場合、お客様には転送に関連する適切な保護措置について通知を受ける権利があります。</p>
        <p><strong>訂正の権利</strong></p>
        <p>お客様は、当社に対し、お客様に関する不正確な個人データの訂正を遅滞なく求める権利を有します。処理の目的を考慮して、お客様は、補足的な声明を提供することにより、不完全な個人データを補完させる権利を有します。</p>
        <p><strong>消去権（「忘れられる権利」）</strong></p>
        <p>以下の条件の少なくとも 1 つが満たされる場合、お客様は当社に対して、お客様に関する個人データの消去を遅滞なく要求する権利を有します。</p>
        <p>a) お客様の個人データが、収集またはその他の処理の目的に関連して不要になった場合。</p>
        <p>b) お客様の個人データはお客様の同意に基づいて処理されており、お客様がその同意を撤回し、当社がその処理を継続するその他の法的根拠がない場合。</p>
        <p>c) お客様が処理に異議を唱え、処理に対する優先する正当な根拠がない場合（例：お客様は、マーケティング目的でのお客様に関する個人データの処理に異議を唱えることができます）。</p>
        <p>d) あなたの個人データが違法に処理された場合</p>
        <p>e) 当社が負う法的義務を遵守するために、お客様の個人データを消去する必要がある場合。</p>
        <p>当社がお客様に関する個人データを消去することを希望し、上記の条件の少なくとも１つが適用されるかどうか、または制限があるかどうか不明な場合は、当社にご連絡いただければ、お客様のご要望を可能な限り最善の方法で解決できるようサポートいたします。
        </p>
        <p><strong>処理の制限の権利</strong></p>
        <p>次のいずれかに該当する処理の場合、制限を受ける権利があります。</p>
        <p>a) 個人データの正確性にお客様が異議を唱えている場合;</p>
        <p>b) 処理が違法であり、個人データの消去に反対し、代わりにその使用の制限を要求する場合;</p>
        <p>c) 当社が処理の目的で個人データを必要としなくなったが、法的請求の確立、行使、または防御のために個人データが必要な場合。</p>
        <p>d) お客様が処理に異議を唱えており、お客様の異議申し立ては現在、当社の正当な理由がお客様の正当な理由に優先するかどうかの検証を待っている状態です。</p>
        <p>処理が制限されている場合、保管を除き、関連する個人データは、お客様の同意がある場合、または法的請求の確立、行使、防御のため、あるいは他の自然人または法人の権利の保護のためにのみ処理されます。</p>
        <p><strong>データポータビリティの権利</strong></p>
        <p>あなたには、あなたに関する個人データの一部を構造化され、一般的に使用され、機械で読み取り可能な形式で受け取り、そのデータを別の管理者に支障なく転送する権利があります。この権利は、あなたに関する以下の個人データに適用されます。
        </p>
        <p>a) あなたから入手した、</p>
        <p>b) お客様の同意または契約に基づいて処理され、</p>
        <p>c) 自動化された手段によって処理される</p>
        <p>当社が上記の基準を満たす個人データを処理する場合、技術的に実行可能な場合、お客様は、当社が当該個人データを直接別の管理者に転送することを要求する権利も有します。</p>
        <p><strong>異議申し立ての権利</strong></p>
        <p>お客様は、ダイレクトマーケティング目的などで当社がお客様に関する個人データを処理することに異議を申し立てる権利を有します。当社は、お客様の異議を一つ一つ慎重に評価し、処理の正当な根拠がない場合には個人データの処理を中止します。
        </p>
        <h3><strong>H. 個人データのセキュリティ</strong></h3>
        <p>当社のサービスには、強力なセキュリティ機能が組み込まれています。当社は厳格な情報管理ポリシーを策定し、専門の技術チームを擁し、不正な閲覧、開示、乱用、変更、破壊、紛失からお客様の個人情報を保護するために、次のような一連の合理的な予防措置を講じています。
        </p>
        <p>1. 暗号化技術を使用して、送信中にデータのプライバシーが確保されるようにします。</p>
        <p>2. アカウントのセキュリティを保護するために複数のセキュリティ機能を提供します。</p>
        <p>3. 権限のない人がシステムにアクセスするのを防ぐため、物理的なセキュリティ対策を含む情報の収集、保管、処理の慣行を見直します。</p>
        <h3><strong>I. 個人情報の保管期間</strong></h3>
        <p>当社が収集した個人データは、その内容と使用方法に応じて、異なる期間保持されます。異なる種類のデータセットを異なる期間保持する理由について説明します。</p>
        <p>1. 収集理由に基づいて、さまざまな種類のデータに異なる保存期間を設定します。たとえば、ドア解放ログや通話履歴など、１月後にデータは自動的に削除されます。</p>
        <p>2. ユーザーアカウント情報など、アカウントが削除されるまで保持される個人情報。</p>
        <p>3. 限定された目的のために長期間にわたって保持される情報。ビジネス、法律、規制の要件により、特定の目的のために特定の情報を長期間にわたって保持することが義務付けられる場合があります。</p>
        <h3><strong>J. 子供のプライバシー</strong></h3>
        <p>当社のサービスが 18 歳未満の子供によってアクセスまたは使用されることは想定していません。18 歳未満の場合は、親または法定後見人の関与がある場合のみ、当社のサービスを利用できます。アカウントが 18
            歳未満の登録ユーザーに関連付けられていることを当社が実際に知った場合、当社はそのアカウントに関連付けられている情報を直ちに削除します。親または後見人であり、お子様がお客様の同意なしに当社に個人情報を提供したことが判明した場合は、support@akuvoxsmartplus.com
            に連絡して、当社のシステムから情報を削除するよう要求してください。</p>
        <p> </p>
        <h3><strong>K. このプライバシーポリシーの更新</strong></h3>
        <p>当社は、このプライバシーポリシーを随時変更および更新することがあります。当社は、通知やその他のフォームを通じて、ウェブサイトおよびモバイル アプリにプライバシーポリシーの更新を掲載します。お客様は、当社のモバイル
            アプリにアクセスするか、当社のウェブサイト https://www.akuvoxsmartplus.com
            にアクセスして、プライバシーポリシーの最新バージョンを確認できます。当社は、主要な変更について正式な通知を送信します (特定のサービスについては、当社のプライバシーポリシーの変更に関する電子メール通知を含む)。
        </p>
        <h3><strong>L. お問い合わせ</strong></h3>
        <p>お客様の個人データの使用またはお客様の対応する権利の行使に関して他にご質問やご要望がある場合、または詳細情報が必要な場合は、support@akuvoxsmartplus.com
            までご連絡ください。お名前（またはニックネーム）、ご希望の連絡方法、ご要望またはコメントの詳細、および対応するウェブサイトのリンクをお知らせください。</p>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
    </div>
</template>

<style lang="scss" scoped>
p {
    margin-top: 10px;
}
.policy-ul {
    left: 20px;
    position: relative;
    width: 90%;
    li {
        list-style: disc;
    }
}
h3 {
    margin-top: 10px;
}
</style>
