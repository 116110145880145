
import {
    defineComponent, ref, reactive, onBeforeUnmount, watch
} from 'vue';
import '@/assets/less/index.less';
import {
    account as accountRequestData, accountType, community, communityType
} from '@/data';
import { horizontal, vertical } from '@/components/common/nav/index';
import textPopover from '@/components/common/text-popover/index.vue';
import selectList, { SelectItem } from '@/components/common/select-list';
import passwordDialog from '@/components/view/common/dialog/password-change';
import billingDialog from '@/components/view/common/dialog/billing';
import documentDialog from '@/components/view/common/dialog/document';
import linkSiteDialog from '@/components/view/common/dialog/link-site';
import linkSucceedDialog from '@/components/view/common/dialog/link-site/link-succeed-dialog.vue';
import router, { propertyBaseRouter, propertySubRouter } from '@/router';
import goBackLogin from '@/router/back-login';
import setLang from '@/methods/setLang';
import ChangeTextByScene from '@/methods/setText';
import { communityForPmApi, pmCommonApi } from '@/api';
import ImportFaceTip from '@/components/view/pm/import-face-tip/index.vue';
import importFaceTipUtil from '@/components/view/pm/import-face-tip/importFaceTipUtil';
import emergencyActionDialog from '@/views/pm/alarm-records/components/emergency-action-dialog.vue';
import PasswordUpdateReminderDialog from '@/components/view/pm/password-update-reminder-dialog';
import eventBus from '@/util/use-event-bus/global-event-bus';
import initWebSocket from '@/components/view/pm/alarm-dialog/composables/property-alarm-ws';
import { initPoll } from '@/components/view/pm/alarm-dialog/composables/property-alarm-Polling';

import initProcess from '@/components/view/pm/alarm-dialog';
import useGetTokenStatus from '@/util/use-get-token-status';
import { useRoute } from 'vue-router';
import { setStorage } from '@/util/storage';
import { setToken } from '@/util/token';
import { ElMessageBox } from 'element-plus';
import {
    getUserOperaData, getMenuList, activeCommunity,
    checkNameForProject, controlPop
} from './util';

let haveChangedToken = false;

export default defineComponent({
    components: {
        horizontal,
        vertical,
        textPopover,
        selectList,
        passwordDialog,
        billingDialog,
        documentDialog,
        linkSiteDialog,
        linkSucceedDialog,
        ImportFaceTip,
        emergencyActionDialog,
        PasswordUpdateReminderDialog
    },
    beforeRouteEnter(from, to, next) {
        if (community.communityInfoRequested.value) {
            next();
        } else {
            communityForPmApi.getPMCommunityDetail((res: communityType.CommunityDetail) => {
                community.ProjectType.value = Number(res.Grade) === accountRequestData.officeRole ? 'office' : 'community';
                community.isNew.value = true;

                if (community.ProjectType.value === 'community' || res.IsNew === '1') {
                    setToken(localStorage.getItem('token') as string, 'PR');
                    haveChangedToken = true;
                    setStorage('identityId', localStorage.getItem('communityID'));
                    setStorage('identityUUID', res.AccountUUID);
                    setStorage('grade', localStorage.getItem('grade'));
                    setStorage('userName', localStorage.getItem('userName'));
                    setStorage('account', localStorage.getItem('account'));
                    setStorage('manageTimeZone', localStorage.getItem('manageTimeZone'));
                    // setStorage('initialization', localStorage.getItem('initialization'));
                    // setStorage('peraccount', localStorage.getItem('peraccount'));
                    setStorage('role', 'pm');
                    setStorage('projectGrade', res.Grade.toString());

                    if (Lang === 'he' || Lang === 'fa') {
                        if (Number(res.Grade) === accountRequestData.officeRole) {
                            window.location.href = '/manager/rtl/#/propertyOffice/home';
                        } else {
                            window.location.href = res.IsNew === '1'
                                ? '/manager/rtl/#/propertyCommunity/home'
                                : '/manager/rtl/#/propertyOldCommunity/home';
                        }
                    } else if (Number(res.Grade) === accountRequestData.officeRole) {
                        window.location.href = '/manager/#/propertyOffice/home';
                    } else {
                        window.location.href = res.IsNew === '1'
                            ? '/manager/#/propertyCommunity/home'
                            : '/manager/#/propertyOldCommunity/home';
                    }
                    return;
                }

                community.communityInfoRequested.value = true;
                community.enableShowLog.value = res.EnableShowLog === 1;
                next();
            });
        }
    },
    setup() {
        const {
            isShowImportFaceTip, dealImportDetails, closeImportFaceTip, initImportFaceTipData, getImportDetails,
            getImportResidentDetail
        } = importFaceTipUtil();

        const route = useRoute();

        onBeforeUnmount(() => {
            clearInterval(projectTimer);
            initImportFaceTipData();
            eventBus.emit('logOut');
            localStorage.setItem('token', '');
        });

        // 告警信息
        initProcess(
            (wsUrl: string) => {
                initWebSocket(eventBus, wsUrl);
            },
            () => {
                initPoll();
            }
        );

        useGetTokenStatus();

        const {
            hideAllPop,
            hideOtherPop,
            controlPopVal
        } = controlPop();
        setLang();
        const menus = ref(getMenuList(1));
        const { userOperaList, fileList } = getUserOperaData();

        const communityList: Array<SelectItem & { isNew: string; UUID: string }> = reactive([]);

        let projectTimer: any = 0;

        if (window.crossPlatformConfig.platform !== 'browser') {
            projectTimer = setInterval(() => {
                communityList.length = 0;
                getCommunityList();
            }, 600000);
        }
        const getCommunityList = () => new Promise((resolve) => {
            const apiFnc = window.crossPlatformConfig.platform === 'browser' ? communityForPmApi.getPropertyCommunityList : communityForPmApi.getLocalAkManageAllForPm;

            apiFnc((data: Array<accountType.GetPropertyCommunityItem>) => {
                // 6.3做办公社区比较是否有重名，重名加提示
                const uniqueData = checkNameForProject(data);
                uniqueData.sort((a, b) => {
                    const locationA = a.Location;
                    const locationB = b.Location;
                    if (locationA < locationB) {
                        return -1;
                    }
                    if (locationA > locationB) {
                        return 1;
                    }
                    return 0;
                }).forEach((item) => {
                    communityList.push({
                        key: item.ID.toString(),
                        label: item.Location,
                        isNew: item.IsNew,
                        Grade: item.Grade,
                        UUID: item.UUID
                    });
                });

                const communityID = localStorage.getItem('communityID');
                // 韩国本地工具逻辑
                if (window.crossPlatformConfig.platform !== 'browser' && !communityList.some((item) => item.key === communityID)) {
                    ElMessageBox.alert(
                        WordList.ManageableDevicesHasBeenUpdated,
                        WordList.TabelMessageBoxAlertRequireFailed,
                        { type: 'error', confirmButtonText: WordList.TabelFootSure }
                    ).finally(() => {
                        goBackLogin();
                    });
                }
                let haveCommunity = false;
                uniqueData.forEach((item) => {
                    if (item.ID === communityID) {
                        haveCommunity = true;
                        activeCommunity.ID = item.ID;
                        activeCommunity.label = item.Location;
                        localStorage.setItem('Grade', item.Grade);
                    }
                });

                if (!haveCommunity) {
                    localStorage.setItem('communityID', uniqueData[0].ID.toString());
                    activeCommunity.ID = uniqueData[0].ID;
                    activeCommunity.label = uniqueData[0].Location;
                }
                resolve(data);
            });
        });

        const getProjectDetail = () => {
            communityForPmApi.getPMCommunityDetail((res: communityType.CommunityDetail) => {
                community.isGetPmInfoComplete.value = true;
                community.IsEncryptPin.value = res.IsEncryptPin;
                community.IsEnableLandline.value = res.EnableLandline;
                community.customizeForm.value = res.CustomizeForm;
                community.LimitQRCode.value = res.LimitQRCode;
                community.isAutoRenew.value = res.IsAutoRenew === 1;
                console.log('community.isAutoRenew.value', community.isAutoRenew.value);
                // 6.5第三方设备需求
                community.hasThirdPartyDevice.value = res.ThirdPartyDevices === 1;
                // 6.5.2 sdk二期是否含有中控设备
                community.hasControlDevice.value = res.ControlDevices === 1;
                // 7.0 pm区分办公、社区角色
                community.ProjectType.value = Number(res.Grade) === accountRequestData.officeRole ? 'office' : 'community';

                // 6.5.2 pm操作个人设备的权限控制(仅新社区实现)
                community.hasHaveAccessArea.value = (
                    community.ProjectType.value === 'community' && res.IsNew === '1'
                ) ? res.IsHaveAccessArea === 1 : true;

                // ins 控制 pm 权限
                community.enableDeleteAccount.value = res.EnableDeleteAccount === 1;
                community.communityInfoRequested.value = true;

                community.defaultCommunalFee.value = res.CommunalFee;
                community.isOpenCommunalFee.value = res.FeatureSwitch === 1;
                community.communalDueDate.value = res.DueDate;

                ChangeTextByScene(community.ProjectType.value);
                // 办公隐藏subscription入口
                if (community.ProjectType.value === 'office') {
                    menus.value = getMenuList(1, community.ProjectType.value);
                } else {
                    community.getPropertyCommunityChargeMode((data: { ChargeMode: string | number }) => {
                        community.ChargeMode.value = data.ChargeMode as number;
                        menus.value = getMenuList(data.ChargeMode, community.ProjectType.value);
                    });
                }
                if (res.SmartHomeUUID !== undefined) {
                    community.smartHomeUUID.value = res.SmartHomeUUID;
                } else {
                    // 清楚上次影响
                    community.smartHomeUUID.value = false;
                }
                community.isNew.value = true;
                if (community.isNew.value) {
                    initImportFaceTipData();
                    getImportDetails();
                    getImportResidentDetail();
                    pmCommonApi.getUnReadLogNum((alert: {
                        Number: number;
                    }) => {
                        community.alertCount.value = Number(alert.Number);
                    });
                } else {
                    // v6.5 初始化导入人脸请求数据  2022/08/01 zyc
                    initImportFaceTipData();
                }
            });
        };

        // 初始数据
        const initData = async () => {
            await getCommunityList();
            getProjectDetail();
        };
        initData();

        const account = String(localStorage.getItem('userName'));

        // 右上角name下拉对话框
        const isShowPasswordDialog = ref(false);
        const isShowBillingDialog = ref(false);
        const isShowLinkSiteDialog = ref(false);
        const isShowLinkSucceedDialog = ref(false);
        const isShowDocumentDialog = ref(false);
        const isShowPasswordUpdateReminder = ref(false);

        pmCommonApi.getPMInfo((res: { IsNoticePasswd: number }) => {
            if (res.IsNoticePasswd === 1) {
                isShowPasswordUpdateReminder.value = true;
            }
        });

        const chooseOpera = (key: string) => {
            hideAllPop();
            switch (key) {
                case 'password':
                    isShowPasswordDialog.value = true;
                    break;
                case 'bill':
                    isShowBillingDialog.value = true;
                    break;
                case 'link-site':
                    isShowLinkSiteDialog.value = true;
                    break;
                case 'knowledgeBase':
                    window.open('https://knowledge.akuvox.com/');
                    break;
                default:
                    break;
            }
        };

        const changeToCommunityOrNewOffice = () => new Promise((resolve) => {
            communityForPmApi.getPMCommunityDetail((res: communityType.CommunityDetail) => {
                if ((Number(res.Grade) === accountRequestData.communityRole || res.IsNew === '1') && !haveChangedToken) {
                    setToken(localStorage.getItem('token') as string, 'PR');
                    haveChangedToken = true;
                    setStorage('identityUUID', res.AccountUUID);
                    setStorage('grade', localStorage.getItem('grade'));
                    setStorage('userName', localStorage.getItem('userName'));
                    setStorage('account', localStorage.getItem('account'));
                    setStorage('manageTimeZone', localStorage.getItem('manageTimeZone'));
                    // setStorage('initialization', localStorage.getItem('initialization'));
                    // setStorage('peraccount', localStorage.getItem('peraccount'));
                    setStorage('role', 'pm');
                    setStorage('projectGrade', res.Grade.toString());

                    if (Lang === 'he' || Lang === 'fa') {
                        if (Number(res.Grade) === accountRequestData.officeRole) {
                            window.location.href = '/manager/rtl/#/propertyOffice/home';
                        } else {
                            window.location.href = res.IsNew === '1'
                                ? '/manager/rtl/#/propertyCommunity/home'
                                : '/manager/rtl/#/propertyOldCommunity/home';
                        }
                    } else if (Number(res.Grade) === accountRequestData.officeRole) {
                        window.location.href = '/manager/#/propertyOffice/home';
                    } else {
                        window.location.href = res.IsNew === '1'
                            ? '/manager/#/propertyCommunity/home'
                            : '/manager/#/propertyOldCommunity/home';
                    }
                } else {
                    getProjectDetail();
                }

                // 旧办公切旧办公时走原来逻辑，刷新页面
                resolve(Number(res.Grade) === accountRequestData.officeRole && res.IsNew === '0');
            });
        });

        const changeCommunity = (communityID: string, refresh = true) => {
            hideAllPop();
            localStorage.setItem('communityID', communityID);
            setStorage('identityId', communityID);
            setStorage('identityUUID', communityList.find((item) => item.key.toString() === communityID)?.UUID);

            activeCommunity.ID = communityID;
            communityList.forEach((item) => {
                if (item.key === communityID) {
                    activeCommunity.label = item.label;
                    localStorage.setItem('Grade', item.Grade!);
                }
            });

            changeToCommunityOrNewOffice().then((needRefresh) => {
                if (refresh && needRefresh) {
                    router.push({
                        path: `/${propertyBaseRouter}/${propertySubRouter.black}`,
                        query: {
                            communityID
                        }
                    });
                }
            });
        };
        const emergencyActionDialogRef = ref();
        function openEmergencyAction() {
            emergencyActionDialogRef.value.dialogVisible = true;
        }

        // 跳转Alarm记录页面
        function toAlarm() {
            router.push(`/${propertyBaseRouter}/${propertySubRouter.alarmRecoeds}`);
        }

        watch(() => route.query, () => {
            if (typeof route.query.communityID === 'string' && route.query.communityID !== '') {
                changeCommunity(route.query.communityID, false);
            }
        }, {
            immediate: true
        });

        return {
            menus,
            userOperaList,
            chooseOpera,
            changeCommunity,
            account,
            isShowPasswordDialog,
            isShowBillingDialog,
            isShowLinkSiteDialog,
            isShowLinkSucceedDialog,
            isShowDocumentDialog,
            isShowPasswordUpdateReminder,
            fileList,
            communityList,
            activeCommunity,
            propertyBaseRouter,
            propertySubRouter,
            hideOtherPop,
            controlPopVal,
            isShowImportFaceTip,
            dealImportDetails,
            closeImportFaceTip,
            openEmergencyAction,
            emergencyActionDialogRef,
            community,
            toAlarm,
            goBackLogin
        };
    }
});
