const messageMap = {
    11001010: WordList.RuleEmailInvalid,
    11001013: WordList.TheDataHasBeenUpdatedTip,
    11001014: WordList.OperationFailed,
    11001015: WordList.OperationFailed,
    11001018: WordList.OperationFailed,
    11001019: WordList.OperationFailed,
    11001020: WordList.InvalidX ? WordList.InvalidX.format(WordList.TabelPersonUserInHtmlName) : '',
    11001021: WordList.InvalidX ? WordList.InvalidX.format(WordList.ProperAllTextMobileNumber) : '',
    11001022: WordList.InvalidX ? WordList.InvalidX.format(WordList.ProperAllTextMobileNumber) : '',
    11001023: WordList.InvalidX ? WordList.InvalidX.format(WordList.ProperAllTextMobileNumber) : '',
    11001024: WordList.InvalidX ? WordList.InvalidX.format(WordList.ProperAllTextMobileNumber) : '',
    11001025: WordList.InvalidX ? WordList.InvalidX.format(WordList.TabelPersonDeviceInHtmlMAC) : '',
    11001026: WordList.OperationFailed,
    11001027: WordList.OperationFailed,
    11001028: WordList.OperationFailed,
    11001029: WordList.OperationFailed,
    11001030: WordList.InvalidX ? WordList.InvalidX.format(WordList.TabelDeviceInHtmlNetwork) : '',
    11001031: WordList.OperationFailed,
    11001032: WordList.OperationFailed,
    11001033: WordList.OperationFailed,
    11001034: WordList.AutopLengthMustBeLess2048Bytes,
    11001035: WordList.OperationFailed,
    11001036: WordList.OperationFailed,
    11001037: WordList.OperationFailed,
    11001038: WordList.OperationFailed,
    11001039: WordList.OperationFailed,
    11001040: WordList.OperationFailed,
    11001041: WordList.OperationFailed,
    11001042: WordList.OperationFailed,
    11001043: WordList.TheDeliveryNameAlreadyExists,
    11001045: WordList.RulePasswordThreeR,
    11001046: WordList.OperationFailed,
    11001047: WordList.OperationFailed,
    11001048: WordList.OperationFailed,
    11001049: WordList.OperationFailed,
    11001050: WordList.OperationFailed,
    11001051: WordList.OperationFailed,
    11001052: WordList.OperationFailed,
    11001055: WordList.ThePasswordIsTooShort,
    11001056: WordList.OperationFailed,
    11011003: WordList.TheLengthLessThenY ? WordList.TheLengthLessThenY.format('32') : '',
    11011004: WordList.TheLengthLessThenY ? WordList.TheLengthLessThenY.format('32') : '',
    11011005: WordList.TheLengthLessThenY ? WordList.TheLengthLessThenY.format('32') : '',
    11011006: WordList.OperationFailed,
    11011007: WordList.TheLengthLessThenY ? WordList.TheLengthLessThenY.format('256') : '',
    11011008: WordList.EmailAlreadyExists,
    11011009: WordList.OperationFailed,
    11011010: WordList.OperationFailed,
    11011011: WordList.OperationFailed,
    11011012: WordList.OperationFailed,
    11011013: WordList.OperationFailed,
    11011015: WordList.OperationFailed,
    11011016: WordList.OperationFailed,
    11011017: WordList.OperationFailed,
    11011018: WordList.OperationFailed,
    11011024: WordList.InvalidX ? WordList.InvalidX.format(WordList.RecodeInHtmlDate) : '',
    11011025: WordList.OperationFailed,
    11021003: WordList.OperationFailed,
    11021004: WordList.OperationFailed,
    11021005: WordList.OperationFailed,
    11021014: WordList.OperationFailed,
    11021015: WordList.OperationFailed,
    11021016: WordList.OperationFailed,
    11021017: WordList.OperationFailed,
    11021018: WordList.OperationFailed,
    11021019: WordList.OperationFailed,
    11021020: WordList.OperationFailed,
    11021021: WordList.OperationFailed,
    11021022: WordList.OperationFailed,
    11021023: WordList.TheXDoseNotExist ? WordList.TheXDoseNotExist.format(WordList.TabelPersonKeyInHtmlUser) : '',
    11021024: WordList.TheXDoseNotExist ? WordList.TheXDoseNotExist.format(WordList.ProperAllTextInstaller) : '',
    11021025: WordList.TheXDoseNotExist ? WordList.TheXDoseNotExist.format(WordList.ProperAllTextProject) : '',
    11021026: WordList.TheXDoseNotExist ? WordList.TheXDoseNotExist.format(WordList.ProperAllTextRoleDistributor) : '',
    11021027: WordList.TheXDoseNotExist ? WordList.TheXDoseNotExist.format(WordList.RDeiviceCommunityDevice) : '',
    11021028: WordList.OperationFailed,
    11021029: WordList.OperationFailed,
    11021030: WordList.FailedToGetData,
    11021031: WordList.FailedToGetData,
    11021032: WordList.TheXDoseNotExist ? WordList.TheXDoseNotExist.format(WordList.TabelUserInHtmlRoleManage) : '',
    11021034: WordList.TheXDoseNotExist ? WordList.TheXDoseNotExist.format(WordList.SubDistributor) : '',
    11021035: WordList.FailedToGetData,
    11021036: WordList.TheXDoseNotExist ? WordList.TheXDoseNotExist.format(WordList.SingleSlave) : '',
    11021037: WordList.TheXDoseNotExist ? WordList.TheXDoseNotExist.format(WordList.PropertyManager) : '',
    11021038: WordList.TheXDoseNotExist ? WordList.TheXDoseNotExist.format(WordList.NavInHtmlMenuUser2) : '',
    11021039: WordList.TheXDoseNotExist ? WordList.TheXDoseNotExist.format(WordList.SubDistributor) : '',
    11031002: WordList.XIsRequired ? WordList.XIsRequired.format(WordList.ProperAllOffice) : '',
    11031003: WordList.FailedToGetData,
    11031006: WordList.XAlreadyExists ? WordList.XAlreadyExists.format(WordList.CompanyName) : '',
    11031007: WordList.RuleCompanyEmpty,
    11031008: WordList.OperationFailed,
    11031009: WordList.OperationFailed,
    11031010: WordList.XIsRequired ? WordList.XIsRequired.format(WordList.TabelUpdateBoxDevice) : '',
    11031011: WordList.OperationFailed,
    11031012: WordList.TheXDoseNotExist.format(WordList.SelectDoor),
    11031013: WordList.OperationFailed,
    11031014: WordList.OperationFailed,
    11031015: WordList.OperationFailed,
    11031016: WordList.OperationFailed,
    11041002: WordList.XAlreadyExists ? WordList.XAlreadyExists.format(WordList.GroupName) : '',
    11041003: WordList.OperationFailed,
    11041004: WordList.OperationFailed,
    11041005: WordList.OperationFailed,
    11041006: WordList.FailedToGetData,
    11041009: WordList.XIsRequired ? WordList.XIsRequired.format(WordList.Group) : '',
    11041011: WordList.RuleCompanyEmpty,
    11041012: WordList.XIsRequired ? WordList.XIsRequired.format(WordList.ProperAllOffice) : '',
    11041013: WordList.OperationFailed,
    11041014: WordList.OperationFailed,
    11041015: WordList.OperationFailed,
    11041016: WordList.OperationFailed,
    11041017: WordList.OperationFailed,
    11041018: WordList.OperationFailed,
    11041019: WordList.OperationFailed,
    11041020: WordList.OperationFailed,
    11041021: WordList.OperationFailed,
    11041022: WordList.OperationFailed,
    11041023: WordList.OperationFailed,
    11041024: WordList.OperationFailed,
    11051003: WordList.OperationFailed,
    11051004: WordList.XIsRequired ? WordList.XIsRequired.format(WordList.Group) : '',
    11051005: WordList.FailedToGetData,
    11051006: WordList.FailedToGetData,
    11051008: WordList.FailedToGetData,
    11051010: WordList.FailedToGetData,
    11051011: WordList.OperationFailed,
    11051012: WordList.FailedToGetData,
    11051013: WordList.FailedToGetData,
    11051014: WordList.FailedToGetData,
    11051016: WordList.EmailAlreadyExists,
    11051017: WordList.XAlreadyExists ? WordList.XAlreadyExists.format(WordList.PhoneNumber) : '',
    11051018: WordList.IDLimitRuleMsg,
    11051019: WordList.IDLimitRuleMsg,
    11051020: WordList.OperationFailed,
    11051021: WordList.OperationFailed,
    11051022: WordList.ProperAllTextSetAccessType,
    11051023: WordList.IDAlreadyExists,
    11051024: WordList.InvalidX ? WordList.InvalidX.format(WordList.LoginVerCode) : '',
    11051025: WordList.InvalidX ? WordList.InvalidX.format(WordList.LoginVerCode) : '',
    11051028: WordList.PasswordIncorrect,
    11051029: WordList.XIsRequired ? WordList.XIsRequired.format(WordList.ProperAllTextID) : '',
    11051030: WordList.OperationFailed,
    11051032: WordList.FailedToGetData,
    11051033: WordList.ProperAllTextactivateFeatureTips,
    11051034: WordList.FailedToGetData,
    11051035: WordList.SipAccountRequired,
    11061003: WordList.FailedToGetData,
    11061004: WordList.OperationFailed,
    11061005: WordList.CharactersLengthLimitMsg ? WordList.CharactersLengthLimitMsg.format(WordList.MessageListTanleTitle, '32') : '',
    11061006: WordList.CharactersLengthLimitMsg ? WordList.CharactersLengthLimitMsg.format(WordList.ProperAllTextMessageContent, '1024') : '',
    11061007: WordList.CharactersLengthLimitMsg ? WordList.CharactersLengthLimitMsg.format(WordList.ProperAllTextMessageContent, '64') : '',
    11061008: WordList.FailedToGetData,
    11061009: WordList.FailedToGetData,
    11061010: WordList.FailedToGetData,
    11061011: WordList.OperationFailed,
    11061012: WordList.FailedToGetData,
    11061013: WordList.YouDoNotHaveTheNecessaryPermissions,
    11061014: WordList.YouDoNotHaveTheNecessaryPermissions,
    11061015: WordList.FailedToGetData,
    11061016: WordList.YouDoNotHaveTheNecessaryPermissions,
    11061017: WordList.YouDoNotHaveTheNecessaryPermissions,
    11070013: WordList.OperationFailed,
    11070014: WordList.XAlreadyExists ? WordList.XAlreadyExists.format(WordList.TabelPersonDeviceInHtmlMAC) : '',
    11070015: WordList.OperationFailed,
    11070016: WordList.OperationFailed,
    11070017: WordList.OperationFailed,
    11070018: WordList.OperationFailed,
    11070019: WordList.OperationFailed,
    11070020: WordList.OperationFailed,
    11070021: WordList.OperationFailed,
    11070025: WordList.FailedToGetData,
    11070026: WordList.OperationFailed,
    11070038: WordList.OperationFailed,
    11070040: WordList.OperationFailed,
    11070041: WordList.XAlreadyExists ? WordList.XAlreadyExists.format(WordList.TabelPersonDeviceInHtmlMAC) : '',
    11070043: WordList.FailedToGetData,
    11081003: WordList.FailedToGetData,
    11081004: WordList.OperationFailed,
    11081005: WordList.FailedToGetData,
    11081006: WordList.OperationFailed,
    11081010: WordList.InvalidX ? WordList.InvalidX.format(WordList.LoginVerCode) : '',
    11081016: WordList.PasswordIncorrect,
    11081021: WordList.TryAgain24Later,
    11090001: WordList.OperationFailed,
    11090002: WordList.OperationFailed,
    11090003: WordList.OperationFailed,
    11090004: WordList.OperationFailed,
    11090005: WordList.FailedToGetData,
    11090008: WordList.FailedToGetData,
    11090010: WordList.OperationFailed,
    11090011: WordList.OperationFailed,
    11090012: WordList.TheXDoseNotExist ? WordList.TheXDoseNotExist.format(WordList.ProperAllOffice) : '',
    11090013: WordList.InvalidX ? WordList.InvalidX.format(WordList.timezone) : '',
    11090014: WordList.OperationFailed,
    11090015: WordList.OperationFailed,
    11090016: WordList.OperationFailed,
    11090017: WordList.FailedToGetData,
    11090018: WordList.FailedToGetData,
    11090019: WordList.FailedToGetData,
    11090020: WordList.XAlreadyExists ? WordList.XAlreadyExists.format(WordList.ProperAllTextOfficeName) : '',
    11090022: WordList.TheXDoseNotExist ? WordList.TheXDoseNotExist.format(WordList.ProperAllOffice) : '',
    11090023: WordList.OperationFailed,
    11090024: WordList.OperationFailed,
    11090025: WordList.YouCanOnlyImportOneTemplate,
    11090026: WordList.YouCanOnlyImportOneTemplate,
    11090027: WordList.OperationFailed,
    11090029: WordList.OperationFailed,
    11090034: WordList.OperationFailed,
    11101001: WordList.OperationFailed,
    11101002: WordList.FailedToGetData,
    11101004: WordList.RuleCompanyEmpty,
    11101005: WordList.XIsRequired ? WordList.XIsRequired.format(WordList.Admin) : '',
    11101006: WordList.FailedToGetData,
    11101007: WordList.OperationFailed,
    11101008: WordList.EmailAlreadyExists,
    11101009: WordList.XAlreadyExists ? WordList.XAlreadyExists.format(WordList.ProperAllTextMobileNumber) : '',
    11101010: WordList.PasswordIncorrect,
    11111002: WordList.OperationFailed,
    11111003: WordList.OperationFailed,
    11111004: WordList.OperationFailed,
    11111005: WordList.OperationFailed,
    11120002: WordList.TheBuildingNameAlreadyExists,
    11120006: WordList.FailedToGetData,
    11120008: WordList.PleaseDeleteDevicesUnderThisBuilding,
    11120010: WordList.OperationFailed,
    11131002: WordList.InvalidX ? WordList.InvalidX.format(WordList.ProperAllTextPIN) : '',
    11131003: WordList.InvalidX ? WordList.InvalidX.format(WordList.KeyAndCardRadioButtonRFCards) : '',
    11131004: WordList.XIsRequired ? WordList.XIsRequired.format(WordList.TabelPersonDeviceInHtmlOwner) : '',
    11131005: WordList.OperationFailed,
    11131006: WordList.XIsRequired ? WordList.XIsRequired.format(WordList.ProperAllOffice) : '',
    11131010: WordList.OperationFailed,
    11131011: WordList.OperationFailed,
    11131012: WordList.XIsRequired ? WordList.XIsRequired.format(WordList.PINOrRFCard) : '',
    11131013: WordList.OperationFailed,
    11131014: WordList.OperationFailed,
    11131015: WordList.XAlreadyExists ? WordList.XAlreadyExists.format(WordList.ProperAllTextPIN) : '',
    11131016: WordList.XAlreadyExists ? WordList.XAlreadyExists.format(WordList.KeyAndCardRadioButtonRFCards) : '',
    11131017: WordList.ProperAllTextSetAccessType,
    11131019: WordList.YouDoNotHaveTheNecessaryPermissions,
    11131020: WordList.YouDoNotHaveTheNecessaryPermissions,
    11131021: WordList.XIsRequired ? WordList.XIsRequired.format(WordList.ProperAllTextPersonnel) : '',
    11131022: WordList.TheDataHasBeenUpdatedTip,
    11131023: WordList.OperationFailed,
    11131024: WordList.TheDataHasBeenUpdatedTip,
    11141002: WordList.OperationFailed,
    11141003: WordList.OperationFailed,
    11141004: WordList.OperationFailed,
    11141005: WordList.OperationFailed,
    11141006: WordList.OperationFailed,
    11141007: WordList.OperationFailed,
    11141008: WordList.YouDoNotHaveTheNecessaryPermissions,
    11141009: WordList.XIsRequired ? WordList.XIsRequired.format(WordList.ProperAllTextAccessGroup) : '',
    11141011: WordList.OperationFailed,
    11141012: WordList.XIsRequired ? WordList.XIsRequired.format(WordList.ProperAllOffice) : '',
    11141014: WordList.OperationFailed,
    11141015: WordList.YouDoNotHaveTheNecessaryPermissions,
    11141017: WordList.YouDoNotHaveTheNecessaryPermissions,
    11141018: WordList.YouDoNotHaveTheNecessaryPermissions,
    11141020: WordList.RuleCompanyEmpty,
    11141021: WordList.YouDoNotHaveTheNecessaryPermissions,
    11141022: WordList.XIsRequired ? WordList.XIsRequired.format(WordList.TabelUpdateBoxDevice) : '',
    11141023: WordList.OperationFailed,
    11150001: WordList.OperationFailed,
    11150002: WordList.OperationFailed,
    11150003: WordList.RuleCompanyEmpty,
    11150004: WordList.OperationFailed,
    11150007: WordList.OperationFailed,
    11150008: WordList.OperationFailed,
    11150009: WordList.YouDoNotHaveTheNecessaryPermissions,
    11150010: WordList.OperationFailed,
    11150011: WordList.OperationFailed,
    11150014: WordList.YouDoNotHaveTheNecessaryPermissions,
    11150015: WordList.OperationFailed,
    11150016: WordList.FailedToGetData,
    11150017: WordList.OperationFailed,
    11150018: WordList.FailedToDeleteHolidayByEvent,
    11150022: WordList.LimitTimeRange,
    11161002: WordList.OperationFailed,
    11161004: WordList.FailedToGetData,
    11161005: WordList.OperationFailed,
    11161006: WordList.OperationFailed,
    11161007: WordList.OperationFailed,
    11161008: WordList.OperationFailed,
    11161009: WordList.OperationFailed,
    11161010: WordList.FailedToGetData,
    11161011: WordList.TheXDoseNotExist.format(WordList.PINAndRFCard),
    11161012: WordList.XIsRequired ? WordList.XIsRequired.format(WordList.PINOrRFCard) : '',
    11161013: WordList.XIsRequired ? WordList.XIsRequired.format(WordList.ProperAllTextAccessGroup) : '',
    11161014: WordList.YouDoNotHaveTheNecessaryPermissions,
    11161015: WordList.XAlreadyExists ? WordList.XAlreadyExists.format(WordList.DeliveryName) : '',
    11161016: WordList.OperationFailed,
    11171002: WordList.OperationFailed,
    11171003: WordList.OperationFailed,
    11171004: WordList.OperationFailed,
    11171005: WordList.OperationFailed,
    11171006: WordList.FailedToGetData,
    11171007: WordList.TheXDoseNotExist ? WordList.TheXDoseNotExist.format(WordList.ProperAllTextTempKey) : '',
    11171008: WordList.FailedToGetData,
    11171009: WordList.OperationFailed,
    11171010: WordList.FailedToGetData,
    11171011: WordList.FailedToGetData,
    11171012: WordList.OperationFailed,
    11171013: WordList.FailedToGetData,
    11171014: WordList.TheXDoseNotExist ? WordList.TheXDoseNotExist.format(WordList.ProperAllOffice) : '',
    11171015: WordList.OperationFailed,
    11171016: WordList.TabelPersonKeyDevice,
    11171017: WordList.OperationFailed,
    11171018: WordList.OperationFailed,
    11171019: WordList.StartTimeNotLaterThanEndTime,
    11171020: WordList.OperationFailed,
    11171021: WordList.FailedToGetData,
    11171022: WordList.OperationFailed,
    11181010: WordList.OperationFailed,
    11181011: WordList.OperationFailed,
    11181012: WordList.OperationFailed,
    11181013: WordList.FailedToGetData,
    11181014: WordList.FailedToGetData,
    11181015: WordList.OperationFailed,
    11181016: WordList.OperationFailed,
    11181017: WordList.OperationFailed,
    11181018: WordList.YouDoNotHaveTheNecessaryPermissions,
    11181019: WordList.YouDoNotHaveTheNecessaryPermissions,
    11181020: WordList.OperationFailed,
    11181021: WordList.FailedToGetData,
    11181022: WordList.OperationFailed,
    11181023: WordList.OperationFailed,
    11181024: WordList.OperationFailed,
    11181025: WordList.OperationFailed,
    11181026: WordList.OperationFailed,
    11191005: WordList.MACAddressNotInTheMACLibrary,
    11191006: WordList.XAlreadyExists ? WordList.XAlreadyExists.format(WordList.TabelPersonDeviceInHtmlMAC) : '',
    11191007: WordList.MACAddressNotInTheMACLibrary,
    11191008: WordList.XAlreadyExists ? WordList.XAlreadyExists.format(WordList.TabelPersonDeviceInHtmlMAC) : '',
    11191009: WordList.InvalidX ? WordList.InvalidX.format(WordList.TabelPersonDeviceInHtmlMAC) : '',
    11191010: WordList.XAlreadyExists ? WordList.XAlreadyExists.format(WordList.TabelPersonDeviceInHtmlMAC) : '',
    11191011: WordList.MACAddressNotInTheMACLibrary,
    11191012: WordList.XAlreadyExists ? WordList.XAlreadyExists.format(WordList.TabelPersonDeviceInHtmlMAC) : '',
    11191013: WordList.XAlreadyExists ? WordList.XAlreadyExists.format(WordList.TabelPersonDeviceInHtmlMAC) : '',
    11191014: WordList.OperationFailed,
    11201002: WordList.OperationFailed,
    11201003: WordList.InvalidIdentityInformationTip,
    11201009: WordList.YouDoNotHaveTheNecessaryPermissions,
    11201010: WordList.YouDoNotHaveTheNecessaryPermissions,
    11201011: WordList.OperationFailed,
    11201015: WordList.OperationFailed,
    11201016: WordList.OperationFailed,
    11201017: WordList.YouDoNotHaveTheNecessaryPermissions,
    11201018: WordList.YouDoNotHaveTheNecessaryPermissions,
    11201021: WordList.OperationFailed,
    11201023: WordList.InvalidIdentityInformationTip,
    11211001: WordList.OperationFailed,
    11211002: WordList.InvalidX ? WordList.InvalidX.format(WordList.PinCode) : '',
    11211003: WordList.InvalidX ? WordList.InvalidX.format(WordList.LockName) : '',
    11211004: WordList.OperationFailed,
    11211005: WordList.FailedToGetData,
    11211007: WordList.OperationFailed,
    11211008: WordList.OperationFailed,
    11211009: WordList.FailedToGetData,
    11211010: WordList.OperationFailed,
    11211011: WordList.FailedToGetData,
    11211012: WordList.FailedToGetData,
    11211013: WordList.OperationFailed,
    11211014: WordList.InvalidX ? WordList.InvalidX.format(WordList.TabelPersonUserInHtmlName) : '',
    11211015: WordList.InvalidX ? WordList.InvalidX.format(WordList.TabelPersonKeyInHtmlCode) : '',
    11211016: WordList.OperationFailed,
    11211017: WordList.OperationFailed,
    11211018: WordList.OperationFailed,
    11211019: WordList.SaltoSameAccountLogin,
    11211020: WordList.OperationFailed,
    11211021: WordList.OperationFailed,
    11211022: WordList.OperationFailed,
    11211023: WordList.OperationFailed,
    11221001: WordList.InvalidX ? WordList.InvalidX.format(WordList.ProperAllTextRelay) : '',
    11221002: WordList.InvalidX ? WordList.InvalidX.format(WordList.AutolockTime) : '',
    11221003: WordList.InvalidX ? WordList.InvalidX.format(WordList.PinCode) : '',
    11221008: WordList.FailedToGetData,
    11221010: WordList.FailedToGetData,
    11221011: WordList.LockAlreadyAddToAnotherApt,
    11221012: WordList.LockAlreadyAddToTheApt,
    11221017: WordList.FailedToGetData,
    11231002: WordList.InvalidX ? WordList.InvalidX.format(WordList.Minutes) : '',
    11231003: WordList.InvalidX ? WordList.InvalidX.format(WordList.NavInHtmlDay) : '',
    11231004: WordList.OperationFailed,
    11231005: WordList.OperationFailed,
    11231006: WordList.OperationFailed,
    11231007: WordList.InvalidX ? WordList.InvalidX.format(WordList.Schedules) : '',
    11231008: WordList.XAlreadyExists ? WordList.XAlreadyExists.format(WordList.TimetableName) : '',
    11231009: WordList.TimetableInUseDeletingFailed,
    11231010: WordList.XAlreadyExists ? WordList.XAlreadyExists.format(WordList.ShiftName) : '',
    11231011: WordList.ShiftInUseDeletingFailed,
    11231012: WordList.InvalidX ? WordList.InvalidX.format(WordList.TabelUserInHtmlCompany) : '',
    11231013: WordList.InvalidX ? WordList.InvalidX.format(WordList.DateTime) : '',
    11231014: WordList.InvalidX ? WordList.InvalidX.format(WordList.NavInHtmlTimeFormat) : '',
    11231015: WordList.InvalidX ? WordList.InvalidX.format(WordList.Param) : '',
    11231017: WordList.ValidTimeConflictsWithExistingSchedule,
    11231018: WordList.InvalidX ? WordList.InvalidX.format(WordList.ProperAllTextRelay) : '',
    11231019: WordList.MultipleSchedulesTimingMustNotCoincide,
    11231020: WordList.StartCheckTimeNotLaterThanStartWorkTime,
    11231021: WordList.EndCheckTimeNotEarlierThanEndWorkTime,
    11231022: WordList.OperationFailed,
    11231023: WordList.LeaveTypeInUseDeletingFailed,
    11231024: WordList.OperationFailed,
    11231025: WordList.StartBreakTimeNotEarlierThanStartWorkTime,
    11231026: WordList.EndBreakTimeNotLaterThanEndWorkTime,
    11231027: WordList.InvalidX ? WordList.InvalidX.format(WordList.ProperAllTextTime) : '',
    11231028: WordList.InvalidX ? WordList.InvalidX.format(WordList.ProperAllTextTime) : '',
    11231029: WordList.XAlreadyExists ? WordList.XAlreadyExists.format(WordList.TheRecord) : '',
    11231030: WordList.InvalidX ? WordList.InvalidX.format(WordList.Param) : '',
    11231031: WordList.InvalidX ? WordList.InvalidX.format(WordList.Param) : '',
    11231033: WordList.XAlreadyExists ? WordList.XAlreadyExists.format(WordList.AttendancePointName) : '',
    11231034: WordList.XAlreadyExists ? WordList.XAlreadyExists.format(WordList.LeaveType) : '',
    11231035: WordList.OperationFailed,
    11231036: WordList.OperationFailed,
    11231037: WordList.OperationFailed,
    11231038: WordList.OperationFailed,
    11231039: WordList.OperationFailed,
    11231040: WordList.DurationCannotBeMoreThanOneYear,
    11231041: WordList.OperationFailed,
    11231042: WordList.InvalidX ? WordList.InvalidX.format(WordList.Param) : '',
    11231043: WordList.PleaseSelectAtLeastOneItem,
    11231044: WordList.TheAmountOfExportedDataTooLarge,
    11231045: WordList.OperationFailed,
    11231046: WordList.OperationFailed,
    11231047: WordList.OperationFailed,
    11231048: WordList.FailedToGetData,
    11231049: WordList.OperationFailed,
    11231050: WordList.OperationFailed,
    11231051: WordList.OperationFailed,
    11231052: WordList.InvalidX ? WordList.InvalidX.format(WordList.Param) : '',
    11231053: WordList.InvalidX ? WordList.InvalidX.format(WordList.ProperAllTextPersonnel) : '',
    11231054: WordList.InvalidX ? WordList.InvalidX.format(WordList.RecheckInTime) : '',
    11231055: WordList.InvalidX ? WordList.InvalidX.format(WordList.RecheckOutTime) : '',
    11231056: WordList.InvalidX ? WordList.InvalidX.format(WordList.RecheckInTime) : '',
    11231057: WordList.InvalidX ? WordList.InvalidX.format(WordList.RecheckOutTime) : '',
    11241002: WordList.OperationFailed,
    11241003: WordList.OperationFailed,
    11241004: WordList.OperationFailed,
    11241005: WordList.OperationFailed,
    11241007: WordList.OperationFailed,
    11241008: WordList.OperationFailed,
    11250003: WordList.YouDoNotHaveTheNecessaryPermissions,
    11250004: WordList.InvalidX ? WordList.InvalidX.format(WordList.Param) : '',
    11250006: WordList.InvalidX ? WordList.InvalidX.format(WordList.Param) : '',
    11250007: WordList.NoLogToBeExported,
    11250008: WordList.TimeFormatInvalid,
    11250009: WordList.ProperAllTextExportLogDate ? WordList.ProperAllTextExportLogDate.format('%s') : '',
    11250010: WordList.NoPermissionExportLog,
    11250011: WordList.ProjectTypeInvalid,
    12000001: WordList.OperationFailed,
    12001001: WordList.YouDoNotHaveTheNecessaryPermissions,
    12001002: WordList.YouDoNotHaveTheNecessaryPermissions,
    12001003: WordList.InvalidX ? WordList.InvalidX.format(WordList.EmergencyActionType) : '',
    12001004: WordList.YouDoNotHaveTheNecessaryPermissions,
    12001005: WordList.OperationFailed,
    12001006: WordList.YouDoNotHaveTheNecessaryPermissions,
    12001007: WordList.OperationFailed,
    12001008: WordList.OperationFailed,
    12001009: WordList.OperationFailed,
    12001016: WordList.Only100DataAtOneTime,
    12001017: WordList.OperationFailed,
    12001018: WordList.FileCheckFailed,
    12001019: WordList.FileCheckFailed,
    12001020: WordList.FileCheckFailed,
    12001021: WordList.InvalidX ? WordList.InvalidX.format(WordList.Param) : '',
    12010001: WordList.OperationFailed,
    12011000: WordList.XIsRequired ? WordList.XIsRequired.format(WordList.TabelPropertyManageEmail) : '',
    12011001: WordList.InvalidVerifyCode,
    12011003: WordList.YouDoNotHaveTheNecessaryPermissions,
    12011004: WordList.XIsRequired ? WordList.XIsRequired.format(WordList.ProperAllTextMobileNumber) : '',
    12011005: WordList.OperationFailed,
    12030001: WordList.InvalidX ? WordList.InvalidX.format(WordList.Param) : '',
    12030002: WordList.OperationFailed,
    12030003: WordList.InvalidIdentityInformationTip,
    12040001: WordList.OperationFailed,
    12040016: WordList.InvalidIdentityInformationTip,
    12060002: WordList.OperationFailed,
    12060003: WordList.InvalidIdentityInformationTip,
    12061001: WordList.OperationFailed,
    12061002: WordList.OperationFailed,
    13001002: WordList.OperationFailed,
    13021025: WordList.InvalidX ? WordList.InvalidX.format(WordList.ProperAllTextPhotos) : '',
    13041101: WordList.InvalidX ? WordList.InvalidX.format(WordList.LoginAccount) : '',
    13041102: WordList.InvalidX ? WordList.InvalidX.format(WordList.TabelPersonKeyInHtmlCode) : '',
    13041103: WordList.InvalidX ? WordList.InvalidX.format(WordList.CodeOrPin) : '',
    13041104: WordList.OperationFailed,
    13041105: WordList.OperationFailed,
    13041106: WordList.OperationFailed,
    13041107: WordList.OperationFailed,
    13041108: WordList.OperationFailed,
    13041109: WordList.OperationFailed,
    13041110: WordList.OperationFailed,
    13041111: WordList.SaltoTokenExpired,
    13041112: WordList.OperationFailed,
    13041113: WordList.OperationFailed,
    13041114: WordList.OperationFailed,
    13041115: WordList.OperationFailed,
    13041116: WordList.SaltoCodeFailedTooManyTip,
    13041117: WordList.SaltoTokenExpired,
    13041118: WordList.OperationFailed,
    13041119: WordList.SaltoAccountBlocked,
    13041120: WordList.RemoteOpenAccessGroup,
    13041121: WordList.IQOffline,
    13041122: WordList.OperationFailed,
    13041123: WordList.OperationFailed,
    13041124: WordList.SaltoAccountNoPermissionToEditLockName,
    13041125: WordList.OperationFailed,
    1000300003: WordList.InvalidID,
    1000300010: WordList.NotFrontView,
    1000300011: WordList.MaskDetected,
    1000300012: WordList.ResolutionIsTooLow,
    1000300013: WordList.NofaceDectected,
    1000300014: WordList.TheFileIsTooLarger,
    1000300015: WordList.TheFaceIsTooLarger,
    1000300016: WordList.TheFaceIsTooSmall,
    1000300017: WordList.MoreThanOneFace,
    1000300018: WordList.FaceNotClearEnough,
    1000300019: WordList.FileFormatError
};
export default messageMap;
