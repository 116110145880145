
import './index.less';
import { defineComponent } from 'vue';
import { useStore } from 'vuex';
import router, {
    distributorBaseRouter, distributorSubRouter, installerBaseRouter, installerSubRouter
} from '@/router';
import { account } from '@/data';
import { insIsDisChange } from '@/data/src/account';
import logoMap from '@/constant/logo';

export default defineComponent({
    props: {
        homeUrl: {
            type: String,
            required: true
        },
        showLogo: {
            default: true
        }
    },
    setup(props, { emit }) {
        const store = useStore();

        const logOut = () => {
            emit('logOut');
        };

        function clickLogo() {
            if (
                [
                    account.superManagerRole.toString(),
                    account.distributorRole.toString(),
                    account.installerRole.toString(),
                    account.communityRole.toString(),
                    account.officeRole.toString()
                ].includes((localStorage.getItem('grade') as string))
                && store.state.enableSmartHome) {
                if (localStorage.getItem('grade') === account.installerRole.toString()
                    && insIsDisChange.value === '1') {
                    router.push(props.homeUrl);
                    return;
                }
                if (localStorage.getItem('grade') !== account.distributorRole.toString()) {
                    localStorage.setItem('InstallerLoginType', '1');
                }
                router.push({
                    path: '/middle-page',
                    query: {
                        joinType: 'logo',
                        akuvoxUrl:
                            localStorage.getItem('grade') === account.distributorRole.toString()
                                ? `/${distributorBaseRouter}/${distributorSubRouter.communityDevice}`
                                : `/${installerBaseRouter}/${installerSubRouter.home}`
                    }
                });
            } else {
                router.push(props.homeUrl);
            }
        }

        return {
            logOut,
            router,
            clickLogo,
            logoMap
        };
    }
});
