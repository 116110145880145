import { isAzServer } from "@/util/location";

type LogoMapKey = 'middlePageLogo' | 'navBarLogo' | 'loginPageLogo';

const originLogoMap = {
    middlePageLogo: require("@/assets/image/akuvox-page.png"),
    navBarLogo: require("@/assets/image/logo.png"),
    loginPageLogo: require("@/assets/image/loginlogo.png"),
};

const azLogoMap = {
    middlePageLogo: require("@/assets/image/akuvox-page_az.png"),
    navBarLogo: require("@/assets/image/logo_az.png"),
    loginPageLogo: require("@/assets/image/loginlogo_az.png"),
};

const logoMap = new Proxy(originLogoMap, {
    get: (target, prop: LogoMapKey) => {
        let logos = target;

        // 阿塞拜疆替换图标
        if (isAzServer()) {
            logos = azLogoMap;
        }

        if (!logos[prop]) {
            console.error(`图标名: ${prop.toString()} 无对应图标`);
        }
        return logos[prop];
    },
    set() {
        console.error("禁止修改公共常量的值");
        return false;
    },
});

export default logoMap;
